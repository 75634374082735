@font-face {
  font-family: 'GTWalsheimPro-Bold';
  src: local('GTWalsheimPro-Bold'), url(../Fonts/GTWalsheimPro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'GTWalsheimPro-Medium';
  src: local('GTWalsheimPro-Medium'), url(../Fonts/GTWalsheimPro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'GTWalsheimPro-Light';
  src: local('GTWalsheimPro-Light'), url(../Fonts/GTWalsheimPro-Medium.ttf) format('truetype');
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: GTWalsheimPro-Medium!important;
}

.grey-bg {
    background-color: #E4E7ED!important;
}

.header {
  width: 100%;
  z-index: 4;
  left: 0;
  top: -1px;
  position: fixed;
  box-shadow: 0px 3px 20px 0px #3f4f6e26;
  height: 60px;
  .logo {
    height: 30px;
    margin: 15px;
    cursor: pointer;
  }
  .header-sign-in-button {
    margin-top: 12px;
    padding: 5px 20px!important;
    float: right;
  }
  .header-sign-in-button:focus, .header-sign-in-button:active {
    outline: none!important;
  }
}

.content-listing {
  margin-bottom: 50px;
  margin-top: 80px;
}

a {
    color: #fa6400!important;
    font-family: GTWalsheimPro-Medium!important;
}

h3 {
  font-family: GTWalsheimPro-Medium!important;
  color: #3c3c3b;
}

  .form-control.search-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: .5; /* Firefox */
  }

  .form-control.search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #FFF;
  }

  .form-control.search-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #FFF;
  }

  .form-control.search-input:focus {
    border-bottom: 2px solid #fa6500;
  }

.nav-bar {
  color: #454A55;
  font-size: 18px;
  .row {
    padding: 10px;
  }
}


.search-icon {
  position: absolute;
  margin-top: 20px;
  font-size: 20px;
  color: #A8ADB5;
  cursor: pointer;
}

.search-close-icon {
  position: absolute;
  margin-top: 17px;
  right: 16px;
  font-size: 24px;
  color: #FFA76F;
  cursor: pointer;
  z-index: 99;
}

.user-icon {
  position: absolute;
  margin-top: 20px;
  font-size: 20px;
  color: #A8ADB5;
  cursor: pointer;
}

.nav-icon {
  position: absolute;
  margin-top: 20px;
  font-size: 22px;
  color: #EC6611;
  cursor: pointer;
}

.form-control:focus {
  background-color: #F5F6FA!important;
  box-shadow: none!important;
}

@media (max-width: 700px) {
  .col-sm-0 {
    display: none;    
  }

  .pagin {
    word-spacing: 3px;
  }

  .search-close-icon {
    position: fixed;
  }

  .single-title { 
    min-width: 100%;
  }

  .header-tiles {
    position: fixed!important;
    width: 100%;
    bottom: 0px;
    box-shadow: 10px 10px 20px 20px #fce7e8;
    background: #FFF;
    z-index: 2;
  }

  .tile {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .trending {
    left: 0px;
    height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: left;
    overflow-y: scroll;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    background: #FFF;
  }

  .book-container {
    position: fixed!important;
    left: 0px;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: left;
    overflow-y: scroll;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    background: #e4e7ed;
  }

  .search-results {
    position: fixed;
    top: 60px;
    background: #EC6611;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    padding: 30px;
    overflow-y: scroll;
    max-height: 500px;
    color: #FFF;
    
    .top-arrow {
      position: fixed;
      top: 50px;
      right: 5%;
    }
  }

  .user-nav {
    position: fixed;
    top: 60px;
    z-index: 3;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    width: 200px;
    padding: 20px;
    right: 20px;
    overflow-y: scroll;
    max-height: 500px;
    line-height: 5px;
    font-size: 14px;
    text-align: left;
    color: #4AC5E3;
    .top-arrow {
      position: fixed;
      top: 50px;
      right: 12%;
    }
    div.col-12 {
      border-bottom: 1px solid #FFF;
      padding: 10px;
      cursor: pointer;
    }
    div.col-12:hover {
      font-weight: bold;
      background-color: #eee;
    }
  }

   .form-control.search-input {
      border-radius: 20px;
      height: 40px;
      margin-top: 10px;
      position: fixed;
      background-color: #F5F6FA;
      border: none;
      width: 98%;
      right: 1%;
      color: #A8ADB5;
      padding-left: 20px;
      z-index: 9;
      padding-top: 6px;
      font-size: 14px;
    }

    .content-listing {
      margin-top: 180px;
    }

    .nav-bar {
        position: fixed;
        z-index: 3;
        background: #F5F6FA;
        padding-top: 60px;
        height: 100%;
        max-width: 100%!important;
        padding-bottom: 50px;
    }

    .expert-book-detail {
      border-bottom: 1px solid #eee;
      padding-top: 15px;
    }

    .item-details-header {
      min-height: 500px;
      background: url('../Images/header-bgblur.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: -50px;
      padding-left: 15px;
      padding-right: 15px;
    
    }
}

@media (min-width: 701px) {
  .col-lg-0 {
    display: none;    
  }
    .expert-book-detail {
      border-right: 1px solid #eee;
      padding-top: 15px;
    }

  .pagin {
    word-spacing: 10px;
  }
  .reading-list-media.products {
      .media-body {
        min-height: 300px!important;
      }
    }
  .tile {
    margin-top: 17px;
    text-align: center;
  }

   .form-control.search-input {
      border-radius: 20px;
      height: 40px;
      margin-top: 10px;
      position: absolute;
      background-color: #F5F6FA;
      border: none;
      width: 250%;
      right: 1%;
      color: #000;
      padding-left: 20px;
      z-index: 9;
      padding-top: 2px;
      font-size: 14px;
    }

  .trending {
    left: 0px;
    height: 100vh;
    padding-top: 60px;
    text-align: left;
    overflow-y: scroll;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    background: #FFF;
    padding-left: 40px!important;
  }

  .dashboard-container{
    height:100vh;
    overflow-y:auto;
  }

  .book-container {
    position: fixed!important;
    left: 0px;
    height: 100%;
    padding-top: 100px;
    text-align: left;
    overflow-y: scroll;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    background: #e4e7ed;
  }

  .search-results {
    position: fixed;
    top: 60px;
    background: #EC6611;
    border-radius: 5px;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    width: 47%;
    right: 10%;
    padding: 30px;
    overflow-y: scroll;
    max-height: 500px;
    color: #FFF;

    .top-arrow {
      position: fixed;
      top: 50px;
      right: 11%;
    }


  }

  .user-nav {
    position: fixed;
    top: 60px;
    z-index: 3;
    background: #FFF;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    width: 300px;
    right: 3%;
    padding: 30px;
    overflow-y: scroll;
    max-height: 500px;
    line-height: 10px;
    text-align: left;
    color: #4AC5E3;
    .top-arrow {
      position: fixed;
      top: 50px;
      right: 5%;
    }
    div.col-12 {
      border-bottom: 1px solid #fff;
      padding: 10px;
      cursor: pointer;
    }
    div.col-12:hover {
      font-weight: bold;
      background-color: #eee;
    }
  }

  .single-title { 
    min-width: 500px;
  }

  .nav-bar {
        position: fixed!important;
        right: 0;
        top: 0;
        height: 100%;
        padding: 50px 0px;
        text-align: left;
        overflow-y: scroll;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
        background: #F5F6FA;
        z-index: 2;
        width: 30%!important;
    }

    .item-details-header {
      min-height: 500px;
      background: url('../Images/header-bgblur.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: -50px;
    }
}

.search-tile {
  flex-direction: row;
  display: flex;
  margin-top: 10px;
  cursor: pointer;
  border-bottom: 1px solid #fefefe;
}

.search-tile img {
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.search-tile div {
  padding: 10px;
}

.margin-top-15 {
  margin-top: 15px; 
}

a {
  cursor: pointer;
}

.btn-primary {
  color: #FFF!important;
}



.trending li{
  display: block;
  cursor: pointer;
}

.margin-top-150 {
  margin-top: 150px;
}

.margin-top-180 {
  margin-top: 180px;
}
.margin-top-120 {
  margin-top: 120px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.product-tile {
  padding: 10px;
  border-radius: 5px;
  .product-rating {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

.product-tile h6 {
  margin-top: 5px;
}

.product-tile:hover {
  cursor: pointer;
  padding: 0px!important;
  .product-rating {
    position: absolute;
    top: 0px;
    right: 5px;
  }
}

.mask {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  left: 0;
  background-color: #000;
  opacity: .7;
  color: #FFF;
}

.loader {
  height: 800px;
  width: 100%;
  top: 0;
  left: 0;
  color: #FFF;
}

.loader img {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.primary-btn {
  background-color: #FFF!important;
  color: #EC6611!important;
  font-family: GTWalsheimPro-Medium!important;
  padding: 5px 0px!important;
  border-radius: 50px!important;
  border: 2px solid #EC6611!important; 
  font-size: 16px;
  height: 40px;
  width: 100%;
}

.primary-btn:hover {
  color: #EC6611!important;
}

.primary-btn.green {
  background-image: linear-gradient(135deg, #4DD287 0%, #59C19A 100%);
  color: #FFF!important;
  font-family: GTWalsheimPro-Medium!important;
  padding: 5px 30px!important;
  border: 2px solid #4DD287!important;
  border-radius: 50px!important;
  font-size: 16px;
  display: block;
  height: 40px;
  width: 100%;
}

.secondary-btn {
  background-image: linear-gradient(135deg, #F79F27 0%, #EC6611 100%);
  color: #FFF!important;
  font-family: GTWalsheimPro-Medium!important;
  padding: 5px 30px!important;
  border-radius: 20px!important;
  border: none; 
  font-size: 16px;
  height: 40px;
}

.home-titles {
  padding: 5px;
  margin-top: 20px;
}

.single-title {
    display: inline-flex;
    background: #FFFFFF;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin-right: 10px;
    &-image{
     margin-top:-50px;
   }
    height: 270px;
    img {
      width: 100%;
      border-radius:10px;
    }
    .desc {
      margin-top: 2px;
      font-size: 14px;
    }
    cursor: pointer;
}

.titles-rows {
  overflow-x: scroll;
  display: flex;
}

::-webkit-scrollbar {
  width: 2px!important;
  z-index: 9999999;
}

::-webkit-scrollbar-thumb {
  background-color: #E4E7ED;
}

::-webkit-scrollbar-track {

}

.loading {
  background-image: url('../Images/loader-1.gif');
  background-size: 80px 20px;
  background-repeat: no-repeat;  
  background-position: center;
  height: 30px;
}

.filters-header {
  font-size: 18px;
  .filter-reset {
    text-align: right;
    color: #4AC5E3;
  }
}

.filters {
  ul {
    font-size: 14px;
    color: #6D737D;
    cursor: pointer;
  }
  h5 {
    font-family: GTWalsheimPro-Medium!important;
    text-transform: capitalize;
    cursor: pointer;
    margin-bottom:  2px!important;
    font-size: 14px;
  }

  .filter-title {
    background-color: #F5F6FA;
    padding: 15px;
    border-radius:10px;
    line-height: 10px;
    margin-top: 5px;
    margin-bottom: 4px;
     height: 46px;
  }

  .filter-keys {
    padding: 10px;
    line-height: 30px;
  }
}

input[type="checkbox"]:checked:after, .checkbox input[type="checkbox"]:checked:after, .checkbox-inline input[type="checkbox"]:checked:after {
  background-color: #ec6611!important;
}

.login-panel {
  .logo {
    height: 40px;
    a {
      color: #fa6400 !important;
    }
  }
}

.ReactModal__Overlay {
  background-color: #3c3c3bad!important;
  z-index: 9;
}

.btn-primary {
  background-color: #fa6500!important;
  border-color: #fa6500!important;;
}

.btn:active, .btn:focus, .btn:hover {
  box-shadow: none!important;
}
.btn:hover {
  color: #fefefe!important;
}


.right-angle {
    position: absolute;
    font-size: 30px;
    right: 0px;
    opacity: 0.2;
    color: #fa6400;
    cursor: pointer;
    margin-top: -170px;
}

.right-angle:hover {
  opacity: 0.6;
}

.left-angle {
    position: absolute;
    font-size: 30px;
    left: 0px;
    opacity: 0.2;
    color: #fa6400;
    cursor: pointer;
    margin-top: 120px;
}

.left-angle:hover {
  opacity: 0.6;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(100px);
  transition: all 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(100px);
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  border-radius: 50px;
  font-size: 25px;
}

.text-white {
  color: #FFF;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
  opacity: .6;
}

.left-nav-tab {
  cursor: pointer; 
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.left-nav-tab:hover {
  background-color: #fa6400;
  color: #FFF;
}

.width-100 {
  width: 100%;
}

.width-200px {
  width: 200px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ccc;
}

.filters-bar {
  padding: 20px;
  .search-icon {
    margin-top: 10px;
    font-size: 16px;
    color: #A8ADB5;
    cursor: pointer;
    text-align: center;
  }
  .filters-bar-header {
    font-size: 18px;
    color: #454A55;
  }
}


.filter-dropdown {
    border-radius: 50px!important;
    font-size: 16px!important;
    background: #F5F6FA!important;
    height: 40px!important;
    border: 10px solid #F5F6FA;
    cursor: pointer;
    width: 100%;
    color: #A8ADB5;
}

.star-rating {
  color: #F6B11F;
  [data-icon="star"] {
    box-shadow: 0px 3px 20px 0px #3f4f6e26;
  }
}

.title {
  color: #4AC5E3;
  font-size: 18px;
  line-height: 22px;
}

.book-container {
  img {
    width: 100%;
    margin: 10px;
    border-radius: 5px;
  }

  .pricing {
    font-size: 12px;
    .col-4 {
      padding-right: 0px;
      padding-left: 0px;
      text-align: center;
    }
  }

  .toc-container {
    width: 108%;
    min-height: 600px;
    background-color: #f5f6fa;
    margin-top: 20px;
  }
}

.padding-100 {
  padding: 100px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-left-5 {
    padding-left: 5px;
}

.mobile-filter {
  margin-top: 55px;
  background-color: #FFF;
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0px;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow:none!important;
}

.left-button-container{
    button {
      transform: rotate(90deg);
      position: fixed;
      top: 140px;
      z-index: 1;
      border: none;
      background: #ec6611;
      color: #FFF;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      /* padding: 0px 20px; */
      padding: 8px;
      &.filters {
        left: 0px!important;
      }
      &.expert-reading {
        left: -60px;
      }
    } 
}

.reading-list-media {
    display: block;
    cursor: pointer;
}

.reading-list-media {
  margin-bottom: 10px;
}

.media {
    align-items: flex-start;
    display: flex;
}

.reading-list-media .img-list.list-size-3 {
    max-width: 236.5px;
}

.reading-list-media .img-list {
    height: 0;
    left: 15px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 20px;
}
.reading-list-media img {
  border-radius:10px;
}

.reading-list-media .img-list .img-0 {
    height: 125px;
    width: 100px;
    z-index: 1;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}
.reading-list-media .img-list .img-1 {
    height: 114px;
    margin-left: -41.5px;
    margin-top: 8px;
    width: 93px;
    z-index: 2;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}
.reading-list-media .img-list .img-2 {
    height: 110px;
    margin-left: -45px;
    margin-top: 12px;
    width: 90px;
    z-index: 3;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.reading-list-media .media-body {
    background-color: #FFF;
    border-radius: 10px;
    margin-top: 100px;
    padding: 60px 20px 25px 20px;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.reading-list-media .reading-list-footer {
  width: 93%;
  left: 15px;
  background: #f5f6fa;
  height: 40px;
  position: absolute;
   border-bottom-right-radius: 10px;
   border-bottom-left-radius: 10px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.reading-list-media.products {
  .media-body {
    background-color: #FFF;
    box-shadow: none;
    margin-top: 130px;
  }

  .product-rating {
      position: absolute;
      top: 135px;
      right: 30px;
  }

  .product-desc {
    color: #6D737D;
    font-size: 14px;
    line-height: 20px;
  }

  .product-author {
    color: #A8ADB5;
    font-size: 14px;
    line-height: 16px;
    margin-top:20px;
  }

  .product-bottom {
    margin-top: 20px;
    color: #454A55;
    font-size: 14px;
  }

  .img-list {
    left: 30px!important;
    right: auto;

    .img-0 {
      width: 125px;
      height: auto;
    }
  }
  .early-eccess {
    background-color: #F6B11F;
    color: #FFF;
    padding: 10px 20px;
    border-radius: 50px;
    position: absolute;
    top: 108px;
    right: 45px;
  }
}

.text-gray {
  color: #6D737D!important;
}

.text-white {
  color: #FFF!important;
}

.text-orange {
      color: #EC6611 !important;
  }


.text-blue {
  color: #4AC5E3!important;
}

.fs-18 {
  font-size: 18px;
}

.error {
  color: red;
}

.open-reader-navigation{
  position:fixed;
  left:0;
  top:70px;
  z-index:2;
  border-radius:0px 8px 8px 0px !important;
}

.reader{
  height:100vh;
  overflow-y:scroll;
}

.reader-content {
  padding:20px;
}

.reader-navigation{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:100;
  overflow-y: auto;
  background-color:rgba(255,255,255,0.5);
  animation: ease-in-out 1s;
  padding-top:25px;
  .wrapper{
    background-color:#E4E7ED;
  }
  &--close{
    position:absolute;
    left:-40px;
    top:-50px;
    width:50px;
    height:50px;
    margin-left:20px;
    border-radius:0px 8px 8px 0px !important;
  }
  &--image{
    width:100%
  }
  &-header{
    padding:30px 30px 10px 30px;
  }
  &-chapters{
    background-color:#F5F6FA;
    overflow: auto;
    height:72vh;
    .toc-container {
      width: 100%;
      background-color: #f5f6fa;
      margin:auto;
      padding:10% 2%;
      margin-bottom:50px;
      &-single-chart{
        justify-content: space-around;
        margin-left:-20px;
        margin-top:-5px;
        svg {
          margin: 0;
          width: 35px;
        }
      }
      .circle-bg {
        fill: none;
        stroke: #eee;
        stroke-width: 3.8;
      }

      .circle {
        fill: none;
        stroke-width: 2.8;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
      }
      .percentage {
        fill: #666;
        font-family: inherit;
        font-size: 1.1em;
        text-anchor: middle;
      }

      .circular-chart.orange .circle {
        stroke: #ec6611;
      }
      .circular-chart.grey .circle {
        stroke: #e0e1e4;
      }


      ul {
        list-style: none;
        width:100%;
        li{
          padding:15px 30px;
          &:hover{
            cursor:pointer;
          }
        }
      }
      &-toggle{
        width:100%;
        &-title{
          color:#939B94 !important;
        }
        &-icon{
          color:#EFA570
        }
      }
      &-sections{
          list-style:none;
          margin-top:15px;
        &-list{
          &-link{
            color:#74BED5 !important;
          }
        }
      }
    }
  }
}

.fade-out {
  border-bottom: 0px solid;
}
.fade-out::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 1rem;
  padding: 75px 0px;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
  background: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
}

.reader-upsell-container {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 28px 0 rgba(63, 79, 110, 0.35);
  width: 700px;
  max-width: 100%;
  text-align: center;
  padding: 40px 5rem;
  margin: auto;
  position: relative;

  .upsell-title {
    color: #ec6611;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  h2 {
    color: #454a55;
    font-size: 26px !important;
    line-height: 32px;
    margin-bottom: 15px;
  }

  h4 {
    color: #6d737d;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .btn-parent {
    margin-top: 30px;

    .button {
      padding: 9px 32px;
    }

    .sign-in {
      margin-top: 10px;

      a {
        color: #4ac5e3;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.video-wrapper{
  width:100%;
  margin:auto;
  border-radius:10px;
}

.product .product__preview .book-content p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 0 2rem 0;
}

.book-content {
  color: #6d737d;
  font-size: 16px;

  .hide {
    display: none;
  }

  .header-title {
    margin-bottom: 2rem;
  }

  h1,
  h2,
  h3,
  h4 {
    color: #454a55;
  }

  h1 {
    font-size: 32px;
    line-height: 48px;
  }

  h2,
  .book-section__video-title {
    font-size: 26px;
    line-height: 38px;
  }

  h3 {
    font-size: 18px;
    line-height: 32px;
  }

  h4 {
    font-size: 16px;
    line-height: 22px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1.5rem;
  }

  .Code-In-Text--PACKT- {
    background: #fceff1;
    color: #f34e66;
    outline: 0;

    span {
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      padding: 2px 4px;
    }
  }

  .Code-In-Text--PACKT- {
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    padding: 2px 4px;
  }

  a {
    font-size: 14px;
    line-height: 22px;
    color: #4ac5e3;
  }

  .button {
    color: #fff;
    font-size: 16px;
    padding: 9px 32px;
  }

  ol,
  ul {
    margin: 3rem 0px;
    font-size: 16px;
    line-height: 24px;
    color: #6d737d;
    padding: 0px 20px;

    li {
      padding-left: 8px;
      margin-bottom: 5px;
    }
  }

  ul {
    padding: 2.5rem;
    background: #f5f6fa;
    border-radius: 10px;
    line-height: 26px;

    & > li {
      margin-bottom: 10px;
      list-style: none !important;
      position: relative;

      p {
        display: inline-block;
      }
    }

    li:last-child {
      margin-bottom: 0px;
    }

    li:before {
      content: '';
      margin-left: -10px;
      display: inline-block;
      background-color: #a8adb5;
      border-radius: 100%;
      width: 11px;
      height: 11px;
      margin-right: 10px;
    }
  }

  pre[class*='language-'] > code {
    background-size: 3.145em 3.145em;
    border-bottom: 0px solid;
  }

  pre {
    code {
      font-size: 14px;
      line-height: 22px;
      overflow: visible;
      color: #454a55;
      white-space: break-spaces;
      overflow-x: auto;
    }
    code:after {
      content: '';
      height: 16px;
      display: block;
    }
  }

  .chapterTitle {
    color: #454a55;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 2.5rem;
  }

  h1.title {
    color: #454a55;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 2rem;
  }

  h2.title {
    font-size: 20px;
  }

  h3.title {
    font-size: 18px !important;
  }

  .book-sections {
    padding-bottom: 3rem;
    padding-top: 3rem;
    border-bottom: 1px solid #e4e7ed;
    position: relative;

    &.fade-out {
      border-bottom: 0px solid;
    }

    &.fade-out::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0px;
      bottom: 3rem;
      padding: 75px 0px;
      background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }
  }

  table {
    width: fit-content;
    max-width: 100%;
    border-style: hidden;
    border-radius: 10px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    box-shadow: 0 0 0 1px #e4e7ed;
    word-break: keep-all;
    display: block;
    overflow-x: auto;

    td,
    th {
      border: 1px solid #e4e7ed;
      padding: 10px 15px;

      p {
        margin-bottom: 0px;
      }
    }

    tr th,
    tr:first-child td,
    tr:first-child td p,
    th p {
      color: #454a55;
      font-size: 14px;
      line-height: 16px;
      background: #f5f5fa;
    }

    td,
    td p {
      color: #454a55;
      font-size: 14px;
      line-height: 22px;
    }
  }

  kbd {
    color: #c7254e;
    background-color: #f9f2f4;
    margin-left: 5px;
    margin-right: 5px;
    word-break: break-word;
    padding: 2px 4px;
    font-size: 90%;
    border-radius: 0;
    font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  }

  ul li.has_sub:after {
    display: block;
    content: '';
    position: absolute;
    border-left: 1px solid #a8adb5;
    width: 100px;
    top: 10px;
    left: 3px;
    height: 30%;
  }

  ul li.has_sub li:after {
    display: block;
    content: ' ';
    position: absolute;
    border-left: 1px solid #a8adb5;
    border-top: 1px solid #a8adb5;
    width: 35px;
    top: 10px;
    left: -30px;
    height: calc(100% + 10px);
  }

  ul li.has_sub li:last-child:after {
    height: 0;
  }

  .packt_infobox a {
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .note,
  .packt_tip,
  .packt_infobox {
    margin: 50px 0px !important;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.7em;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 30px 0 rgba(89, 83, 240, 0.2);
    position: relative;
  }

  .note:before,
  .packt_tip:before,
  .packt_infobox:before {
    content: 'Pro Tip';
    display: block;
    font-size: 1.1rem;
    vertical-align: middle;
    color: #fff;
    font-weight: 100;
    position: absolute;
    top: -1.3rem;
    background-color: #8885e3;
    border-radius: 20px;
    padding: 0.2rem 1.2rem;
    left: 2rem;
  }

  .packt_infobox:before {
    content: 'Infobox';
  }

  .note:before {
    content: 'Note';
  }

  .packt_quote {
    background: #51a796;
    color: white;
    font-weight: 300;
    font-style: italic;
    line-height: 1.8em;
    padding: 15px;
    border-left: 15px solid #3c8c7c;
    border-radius: 4px;
    margin-bottom: 30px;
    margin-right: 0;
    margin-left: 0;
  }

  .mediaobject {
    text-align: center;
  }

  .large {
    transform: scale(2);
  }

  img {
    margin-top: 1rem;
    margin-bottom: 2rem;
    max-width: 60rem;
    height: auto !important;
  }

  @media (max-width: 700px) {
    img {
      max-width: 100%;
    }
  }
}

.upsell-banner {
  color: #FFF;
  height: 370px;
  text-align: center;
  .upsell-banner-title {
    padding-top: 120px;
    line-height: 45px;
  }
  .upsell-banner-button {
    margin-top: 10px;
    height: 40px;
    display: inline-block;
    padding: 11px!important;
  }
}

a {
  text-decoration: none!important;
}

.expert-details {
  padding: 35px;
  .expert-books {
    min-height: 200px;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    margin-bottom: 10px;
    padding-top: 30px;
  }
  .expert-book-image {
    width: 108%;
    border-radius: 10px;
    margin-left: -40px;
  }

  .expert-books-right-panel {
    padding-top: 15px;
  }
}


.item-details {
  .reader-navigation-chapters {
    height: 538px;
    overflow: scroll;
  }

  .item-details-img {
    border-radius: 10px;
    width: 100%;
  }

  .item-details-title {
    color: #FFF;
    font-size: 26px;
    line-height: 32px;
  }

  .item-details-desc {
    
  } 

  .item-details-purchase {
    color: #FFF;
    padding: 5px;
    border-bottom: 1px solid #FFF;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

code[class*='language-'],
pre[class*='language-'] {
  color: black;
  background: none;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*='language-'] {
  position: relative;
  margin: 0.5em 0;
  overflow: visible;
  padding: 0;
}
pre[class*='language-'] > code {
  position: relative;
  background-color: #eceef3;
  background-image: linear-gradient(transparent 50%, #f5f6fa 50%);
  background-size: 3em 3em;
  background-origin: content-box;
  background-attachment: local;
  border-top: 16px solid #f5f6fa;
  border-bottom: 16px solid #f5f6fa;
  border-radius: 16px;
}

code[class*='language'] {
  max-height: inherit;
  height: inherit;
  padding: 0 1em;
  display: block;
  overflow: auto;
}

/* Margin bottom to accommodate shadow */
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background-color: #fdfdfd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 4rem;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  position: relative;
  padding: 0.2em;
  border-radius: 0.3em;
  color: #c92c2c;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline;
  white-space: normal;
}

:not(pre) > code[class*='language-']:after,
pre[class*='language-']:after {
  right: 0.75em;
  left: auto;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
}

div.code-toolbar {
  position:relative;
}

div.code-toolbar > .toolbar {
  position: absolute !important;
  top: -0.1em !important;
  right: 0.5em !important;
}

/* Separate line b/c rules are thrown out if selector is invalid.
   IE11 and old Edge versions don't support :focus-within. */
div.code-toolbar:focus-within > .toolbar {
  opacity: 1;
}

div.code-toolbar > .toolbar .toolbar-item {
  display: inline-block;
}

div.code-toolbar > .toolbar a {
  cursor: pointer;
}

div.code-toolbar > .toolbar a,
div.code-toolbar > .toolbar button,
div.code-toolbar > .toolbar span {
  color: #ff9b51;
  font-size: 1em;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

div.code-toolbar > .toolbar button {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 3px 5px;
  border: none !important;
  outline: none !important;
  height: 35px;
  box-shadow: 0px 3px 20px 0px #3f4f6e26;
}

div.code-toolbar > .toolbar a:hover,
div.code-toolbar > .toolbar a:focus,
div.code-toolbar > .toolbar button:hover,
div.code-toolbar > .toolbar button:focus,
div.code-toolbar > .toolbar span:hover,
div.code-toolbar > .toolbar span:focus {
  color: inherit;
  text-decoration: none;
}

pre[class*='language-'] > code {
  border-top: 30px solid #f5f6fa;
}


#notification {
  position:fixed;
  left:0;
  right:0;
  top:0;
  height:100px;
  background-color:rgba(0,0,0,0.7);
  z-index:100000;
  color:#ffffff;
  padding:20px;
  font-size:1.3em;
  text-align:center;
}

.prompt-container{
  background-color:rgba(0,0,0,0.5);
  position:fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:10;
  &-body{
    position:absolute;
    bottom:0;
    right:0;
    left:0;
    background-color:#fff;
    padding:20px;
    &-logo {
      height:70px;
      margin-top:-70px;
      background-color:#ffffff;
      padding:20px;
      border-radius:10px;
    }
    &-icon {
      margin: auto 15px 8px;
    }
  }
}

@media screen and (max-width: 765px) {
  .reader-navigation {
    &-header{
      padding:10px;
    }
    .wrapper {
      width:100%;
      margin-top:20%;
      padding:10px;
    }
  }
  .book-content{
    padding:20px;
  }
  .noScroll {
    overflow: hidden;
    height:80vh;
  }
}

.display-flex {
  display: flex;
}

